import React from 'react'
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    IconButton,
    MenuItem,
    Menu,
    Link,
    useMediaQuery
} from '@mui/material'

import AccountCircle from '@mui/icons-material/AccountCircle';
// import { HashLink as Link } from 'react-router-hash-link';

const Header = () => {
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const matches = useMediaQuery('(min-width:600px)');



    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box sx={{ flexGrow: 1 }} >
            <AppBar
                position="relative"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Typography variant="h3" color="primary.main" noWrap sx={{ flexGrow: 1 }}>
                        Venyou
                    </Typography>
                    {matches && <nav>
                        <Link
                            variant="button"
                            color="primary.main"
                            href="#features"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            Features
                        </Link>
                        {/* <Link
                            variant="button"
                            color="primary.main"
                            href="https://151.106.124.44"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            Blogs
                        </Link> */}
                        {/* <Link
                            variant="button"
                            color="primary.main"
                            href="/pricing"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            Pricing
                        </Link> */}
                        <Link
                            variant="button"
                            color="primary.main"
                            href="#join-venyou"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            Join Venyou
                        </Link>
                        <Link
                            variant="button"
                            color="primary.main"
                            href="/community-guidelines"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            Community Guidelines
                        </Link>
                        <Link
                            variant="button"
                            color="primary.main"
                            href="#subscribe"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            Subscribe
                        </Link>
                        <Link
                            variant="button"
                            color="primary.main"
                            href="/support"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            Support
                        </Link>
                        <Link
                            variant="button"
                            color="primary.main"
                            href="#contact-us"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            Contact Us
                        </Link>
                    </nav>}
                    {auth && (
                        <div >
                            {matches && <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>}
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleChange}>Login</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </Box>

    )
}

export default Header